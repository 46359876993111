<template lang="pug">
  .test
    canvas(ref="canvas")
</template>
<script>
/* eslint-disable */
import {
  Color3,
  CubeTexture,
  Engine,
  HemisphericLight,
  Matrix,
  MeshBuilder,
  Scene,
  ShaderMaterial, StandardMaterial, Texture,
  Vector3
} from '@babylonjs/core'
import * as BABYLON from "@babylonjs/core";
export default {
  name: 'Test',
  data() {
    return {
      progress: 0
    }
  },

  mounted() {
    this.init()
  },

  destroyed() {
    this._scene.dispose()
    this._engine.dispose()
  },

  methods: {
    move() {
      this._target = this._target === this.plane2 ? this.plane1 : this.plane2

      this._toPosition = this._target.position.clone()
      this._toPosition.z += -1

      this._distance = Vector3.Distance(this._camera.position, this._toPosition)
      this._currentDistance = this._distance

      this._speed = this._distance / 100
      this._direction = this._toPosition.subtract(this._camera.position).normalize()

      this._direction.x *= this._speed
      this._direction.y *= this._speed
      this._direction.z *= this._speed

      if (this._target === this.plane1) {
        this.plane2.position = this._toPosition.clone()
        this.plane2.position.z += 10
      } else {
        this.plane1.position = this._toPosition.clone()
        this.plane1.position.z += 10
      }
    },

    init() {
      this._engine = new Engine(this.$refs.canvas, true)
      this._scene = new Scene(this._engine)

      this._camera = new BABYLON.UniversalCamera("UniversalCamera", new BABYLON.Vector3(0, 0, 0), this._scene)
      this._camera.invertRotation = true
      this._camera.position = BABYLON.Vector3.Zero()
      this._camera.attachControl(this._canvas, true)
      this._camera.angularSensibility = 1000
      this._camera.minZ = 0

      this._engine.runRenderLoop(() => {
        if (this._target) {

          this._currentDistance = Vector3.Distance(this._camera.position, this._toPosition)
          this._camera.position = this._camera.position.add(this._direction)

          //const progress = this._currentDistance / this._distance

          if (Vector3.Distance(this._camera.position, this._toPosition) < this._speed * 2) {
            this.move()
          }
        }
        this._scene.render()
      })

      this.plane1 = MeshBuilder.CreateBox('plane', {
        size: 100
      }, this._scene)

      this.plane1.position.z = 0

      // this.plane2 = MeshBuilder.CreateBox('plane', {
      //   size: 4
      // }, this._scene)

      // this.plane2.position.z = 15

      BABYLON.Effect.ShadersStore.transparentSkyBoxVertexShader = "\r\nprecision highp float;" +
          "\r\nattribute vec3 position;" +
          "\r\nuniform mat4 worldViewProjection;" +
          "\r\nvarying vec3 vPosition;" +
          "\r\nvoid main(void) {" +
          "\r\n     vec4 p = vec4( position, 1. );" +
          "\r\n     vPosition = position;" +
          "\r\n     gl_Position =  worldViewProjection * p;" +
          "\r\n" +
          "}" +
          "\r\n";

      BABYLON.Effect.ShadersStore.transparentSkyBoxFragmentShader = "\r\nprecision highp float;" +
          "\r\nvarying vec3 vPosition;" +
          "\r\nuniform samplerCube textureCubeSampler1;" +
          "\r\nuniform samplerCube textureCubeSampler2;" +
          "\r\nuniform mat4 reflectionMatrix1;" +
          "\r\nuniform mat4 reflectionMatrix2;" +
          "\r\nuniform float progress;" +
          "\r\nvoid main(void) {" +
          "\r\n    vec3 coords1 = (reflectionMatrix1 * vec4(vPosition, 1.0)).xyz;" +
          "\r\n    vec3 coords2 = (reflectionMatrix2 * vec4(vPosition, 1.0)).xyz;" +
          "\r\n    vec4 baseColor1 = textureCube(textureCubeSampler1, coords1);" +
          "\r\n    vec4 baseColor2 = textureCube(textureCubeSampler2, coords2);" +
          "\r\n    gl_FragColor = mix(baseColor1, baseColor2, progress);" +
          "\r\n" +
          "}" +
          "\r\n";

      // const texture1 = new CubeTexture('/tests/box1/skybox', this._scene)
      // texture1.coordinatesMode = Texture.SKYBOX_MODE
      //
      // const material1 = new StandardMaterial('1', this._scene)
      // material1.reflectionTexture = texture1
      // this.plane1.material = material1

      let skyboxMaterial = new BABYLON.StandardMaterial("skyBox", this._scene)
      const texture = new BABYLON.CubeTexture("tests/box2/CubeMap_04/Panorama4", this._scene)
      texture.coordinatesMode = Texture.SKYBOX_MODE

      const texture2 = new BABYLON.CubeTexture("tests/box2/CubeMap_04/Panorama4", this._scene)
      texture2.coordinatesMode = Texture.SKYBOX_MODE
      // skyboxMaterial.backFaceCulling = false
      // skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("tests/box1/skybox", this._scene)
      // skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE
      // skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0)
      // skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0)

      // this.plane1.material = skyboxMaterial

      // const texture2 = new CubeTexture('/tests/2.png', this._scene)
      // const material2 = new StandardMaterial('2', this._scene)

      const material1 = new ShaderMaterial(
        "shader",
        this._scene,
        {
          vertex: "transparentSkyBox",
          fragment: "transparentSkyBox",
        },
        {
          attributes: ["position", "normal", "uv"],
          uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
          needAlphaBlending: true
        },
      )

      material1.setTexture('textureCubeSampler1', texture)
      material1.setTexture('textureCubeSampler2', texture2)
      material1.setMatrix('reflectionMatrix1', Matrix.Translation(0,0,0))
      material1.setFloat('progress', 0)
      material1.backFaceCulling = false
      material1.emissiveColor = Color3.White()

      this.plane1.material = material1

      // const material2 = new ShaderMaterial(
      //     "shader",
      //     this._scene,
      //     {
      //       vertex: "transparentSkyBox",
      //       fragment: "transparentSkyBox",
      //     },
      //     {
      //       attributes: ["position", "normal", "uv"],
      //       uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      //       needAlphaBlending: true
      //     },
      // )

      // material1.setTexture('textureCubeSampler1', texture1)
      // material1.setTexture('textureCubeSampler2', texture2)
      // material1.setMatrix('reflectionMatrix1', Matrix.Translation(0,0,0))
      // material1.setMatrix('reflectionMatrix2', Matrix.Translation(0,0,0))
      // material1.setFloat('progress', 0.5)
      // material1.emissiveColor = Color3.White()

      // material2.setTexture('textureCubeSampler1', texture1)
      // material2.setTexture('textureCubeSampler2', texture2)
      // material2.setMatrix('reflectionMatrix1', Matrix.Translation(0,0,0))
      // material2.setMatrix('reflectionMatrix2', Matrix.Translation(0,0,0))
      // material2.setFloat('progress', 0.5)

      let i = 0
      let flipped = false
      setInterval(() => {
        i+= 0.01
        // material1.setFloat('progress',  Math.abs(Math.cos(i)))
        // material1.setMatrix('reflectionMatrix2', Matrix.Translation(-Math.cos(i) * 3,0,Math.cos(i) * 10))
        //
        // // this._camera.position = new Vector3(-Math.cos(i) * 3,0,Math.cos(i) * 10)
        //
        // if (i > Math.PI * 2) {
        //   i = 0
        //   flipped = !flipped
        //   if (flipped) {
        //     material1.setTexture('textureCubeSampler1', texture2)
        //     material1.setTexture('textureCubeSampler2', texture)
        //   } else {
        //     material1.setTexture('textureCubeSampler1', texture)
        //     material1.setTexture('textureCubeSampler2', texture2)
        //   }
        // }
      }, 10)
      // material2.diffuseTexture = texture2
      // material2.emissiveColor = Color3.White()
      // material2.alpha = 0.7

      // this.plane2.material = material2

      this._light = new HemisphericLight('test', Vector3.Up(), this._scene)
      // this.move()
    }
  }
}
</script>

<style>
.test{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.test canvas {
  width: 100%;
  height: 100%;
}
</style>
