import {
  Axis, Color3,
  Mesh,
  MeshBuilder,
  PBRMetallicRoughnessMaterial,
  Quaternion,
  Texture,
  Vector3
} from "@babylonjs/core";
import {isMobile} from "mobile-device-detect";

class TourMouseMarker {
  _mesh

  /**
   * @return {Scene}
   * @private
   */
  get _scene() {
    return this._sceneController.scene
  }

  /**
   * @type {TourSceneController}
   * @private
   */
  _sceneController

  constructor({sceneController}) {
    this._sceneController = sceneController
    this._createMesh()
  }

  _createMesh() {
    this._mesh = MeshBuilder.CreatePlane('mouse_marker', {
      width: 0.22,
      height: 0.22,
      sideOrientation: Mesh.DOUBLESIDE
    }, this._scene)

    const material = new PBRMetallicRoughnessMaterial('marker_material', this._scene)
    material.baseTexture = new Texture('/ui-textures/mouse-marker.png', this._scene)
    material.baseTexture.hasAlpha = true

    material.baseColor = Color3.White()
    material.emissiveColor = Color3.White()
    material.alpha = 0.6
    // material.diffuseColor = Color3.White()
    //material.emissiveColor = Color3.White()


    this._mesh.isPickable = false

    this._mesh.material = material
    this._mesh.visibility = isMobile ? 0 : 0.6

    this._mesh.renderingGroupId = 3
  }

  setPickInfo(pickInfo) {
    const norm = pickInfo.pickedMesh.getFacetNormal(pickInfo.faceId).normalize()
    this._mesh.position = pickInfo.pickedPoint.clone()

    const axis = Vector3.Cross(Axis.Z, norm)
    const angle = Math.acos(Vector3.Dot(norm, Axis.Z))
    this._mesh.rotationQuaternion = Quaternion.RotationAxis(axis, angle)
  }

  dispose() {
    this._mesh.dispose()
  }
}

export default TourMouseMarker
