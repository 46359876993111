<template lang="pug">
  .attention-popup-container(:class="{hidden: !visible}")
    .attention-popup
      .close-button(@click="onCloseButtonClick")
      .text Корректная работа 3D-тура зависит от устройства пользователя. Ограниченная работоспособность может возникнуть из-за слабой графической карты или процессора вашего устройства, устаревшей операционной системы или недостаточного объема памяти. В случае, если вы сталкиваетесь с некорректной работой 3D-тура, попробуйте воспользоваться другим устройством.
</template>
<script>
export default {
  name: 'AttentionPopup',
  data() {
    return {
      visible: true
    }
  },

  created() {
    this.visible = !(document.referrer && document.referrer.indexOf(window.location.host) >= 0)
  },

  methods: {
    onCloseButtonClick() {
      this.visible = false
    }
  }
}
</script>
