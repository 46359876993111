import {BooleanOperations, Polygon} from "@flatten-js/core";
import CubeFaceMesh from "@/classes/merger/CubeData/CubeFaceMesh.class";

/**
 * @author Aleksand Simonov aka fatalius,
 */
class CubeFace {
  static Orientations = {
    pz: 'pz',
    nz: 'nz',
    px: 'px',
    nx: 'nx',
    py: 'py',
    ny: 'ny'
  }

  /**
   * @type {Polygon}
   * @private
   */
  _polygon

  _orientation

  /**
   * @type {CubeData}
   */
  cubeData

  /**
   * @type {Vector3[]}
   * @private
   */
  _vertices

  constructor({orientation, vertices, cubeData}) {
    this._orientation = orientation
    this._vertices = vertices
    this.cubeData = cubeData

    this._createPolygon()
  }

  /**
   *
   * @return {Polygon}
   */
  get polygon() {
    return this._polygon
  }

  /**
   * @return {Vector3[]}
   */
  get vertices () {
    return this._vertices
  }

  get orientation() {
    return this._orientation
  }

  _createPolygon() {
    this._polygon = new Polygon(this._flattenVertices(this._vertices))
  }

  /**
   *
   * @param vertices {Vector3[]}
   * @return {Vector[]}
   * @private
   */
  _flattenVertices(vertices) {
    const flatVertices = []

    vertices.forEach((vertex) => {
      switch (this._orientation) {
        case CubeFace.Orientations.pz:
        case CubeFace.Orientations.nz:
          flatVertices.push([vertex.x, vertex.y])
          break

        case CubeFace.Orientations.px:
        case CubeFace.Orientations.nx:
          flatVertices.push([vertex.z, vertex.y])
          break

        case CubeFace.Orientations.py:
        case CubeFace.Orientations.ny:
          flatVertices.push([vertex.x, vertex.z])
          break
      }
    })

    return flatVertices
  }

  /**
   *
   * @param vertexEdge {CubeVertexEdges}
   */
  applyVertexEdge(vertexEdges) {
    const subtractionPolygon = this._getSubtractionPolygon(vertexEdges)
    if (subtractionPolygon) {
      // console.log('1', this._polygon, subtractionPolygon)
      // BooleanOperations
      try {
        // console.log('sub', BooleanOperations.subtract(this._polygon, subtractionPolygon))
        this._polygon = BooleanOperations.subtract(this._polygon, subtractionPolygon)
      } catch (e) {
        console.log('!!!!', this.cubeData)
      }
    }
  }

  _getSubtractionPolygon(vertexEdges) {
    let polygon

    switch (this.orientation) {
      case CubeFace.Orientations.nz:
      case CubeFace.Orientations.pz: {

        let x = vertexEdges.pivot.x
        let y = vertexEdges.pivot.y

        let width = (vertexEdges.rays.px ? vertexEdges.rays.px.vertex.x : vertexEdges.rays.nx.vertex.x) - x
        let height = (vertexEdges.rays.py ? vertexEdges.rays.py.vertex.y : vertexEdges.rays.ny.vertex.y) - y

        let points = [
          [x, y], [x, y + height],
          [x + width, y + height], [x + width, y]
        ]

        if (height < 0 && width > 0 || height > 0 && width < 0) {
          points = points.reverse()
        }

        polygon = new Polygon(points)
      }
        break

      case CubeFace.Orientations.nx:
      case CubeFace.Orientations.px: {
        let x = vertexEdges.pivot.z
        let y = vertexEdges.pivot.y

        let width = (vertexEdges.rays.pz ? vertexEdges.rays.pz.vertex.z : vertexEdges.rays.nz.vertex.z) - x
        let height = (vertexEdges.rays.py ? vertexEdges.rays.py.vertex.y : vertexEdges.rays.ny.vertex.y) - y

        let points = [
          [x, y], [x, y + height],
          [x + width, y + height], [x + width, y]
        ]

        if (height < 0 && width > 0 || height > 0 && width < 0) {
          points = points.reverse()
        }

        polygon = new Polygon(points)
      }
        break

      case CubeFace.Orientations.ny:
      case CubeFace.Orientations.py: {
        let x = vertexEdges.pivot.x
        let y = vertexEdges.pivot.z

        let width = (vertexEdges.rays.px ? vertexEdges.rays.px.vertex.x : vertexEdges.rays.nx.vertex.x) - x
        let height = (vertexEdges.rays.pz ? vertexEdges.rays.pz.vertex.z : vertexEdges.rays.nz.vertex.z) - y

        let points = [
          [x, y], [x, y + height],
          [x + width, y + height], [x + width, y]
        ]

        if (height < 0 && width > 0 || height > 0 && width < 0) {
          points = points.reverse()
        }

        polygon = new Polygon(points)
      }
        break
    }

    return polygon
  }

  /**
   *
   * @return {CubeFaceMesh}
   */
  getMesh() {
    const mesh = new CubeFaceMesh({
      cubeFace: this
    })
    return mesh
  }
}

export default CubeFace
