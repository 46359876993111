<template lang="pug">
  .preloader(:style="style", @transitionend="onTransitionEnd")
    img(:src="preloaderImage")
    .percents(v-if="percents") {{displayPercents}}
</template>
<script>
export default {
  name: 'Preloader',
  props: [
    'visible',
    'percents',
    'tourName'
  ],
  data() {
    return {
      preloaderImage: require('@/assets/images/preloader.png'),
      windowHeight: 0,
      currentPercents: 0,
      interval: null,
      transitionComplete: false
    }
  },

  created() {
    window.addEventListener('resize', this.onResize)
    this.onResize()

    this.interval = setInterval(() => {
      this.currentPercents += (this.percents - this.currentPercents) / 10
    }, 1000 / 25)
  },

  destroyed() {
    window.removeEventListener('resize', this.onResize)
    clearInterval(this.interval)
  },

  computed: {
    style() {
      return {
        opacity: this.visible ? 1 : 0,
        display: this.transitionComplete ? 'none' : 'flex'
      }
    },

    displayPercents() {
      return (this.currentPercents * 100).toFixed(2)  + '%'
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.transitionComplete = false
      }
    }
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
    },

    onTransitionEnd() {
      if (!this.visible) {
        this.transitionComplete = true
      }
    }
  }
}
</script>
