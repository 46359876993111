import {Vector3} from "@babylonjs/core";

class CubeMapsData {
  static list = [
    {
      name: 'room1',
      title: 'Комната 1',
      points: [],
      initRotation: Math.PI / 2
    },
    {
      name: 'room2',
      title: 'Комната 2',
      points: [],
      initRotation: Math.PI / 3
    },
    {
      name: 'room3',
      title: 'Комната 3',
      points: [],
      initRotation: Math.PI
    },
    {
      name: 'prachka',
      title: 'Прачечная',
      points: [],
      initRotation: Math.PI,
      locationPoints: [
        {
          cubeId: 'CubeMap_01',
          containsIds: ['CubeMap_01'],
          title: 'Лобби',
          locationName: 'tower',
          preview: 'tower.png',
          position: new Vector3(-0.7, 0, -2.83)
        },
        {
          initialPointId: 'CubeMap_174',
          containsIds: [],
          title: 'Ресторан',
          locationName: 'tower',
          preview: 'bar.png',
        },
        {
          initialPointId: 'CubeMap_192',
          containsIds: [],
          title: 'Кафе и кинотеатр',
          locationName: 'tower',
          preview: 'coworking.png',
        },
        {
          initialPointId: 'CubeMap_80',
          containsIds: [],
          title: 'Лаунж-зона',
          locationName: 'tower',
          preview: 'lounge.png',
        },
        {
          initialPointId: 'CubeMap_47',
          containsIds: [],
          title: 'Сайкл',
          locationName: 'tower',
          preview: 'cycle.png',
        },
        {
          initialPointId: 'CubeMap_53',
          containsIds: [],
          title: 'Тренажерный зал',
          locationName: 'tower',
          preview: 'fitnes.png',
        },
        {
          initialPointId: 'CubeMap_70',
          containsIds: [],
          title: 'Женская раздевалка',
          locationName: 'tower',
          preview: 'woman-room.png',
        },
        {
          initialPointId: 'CubeMap_63',
          containsIds: [],
          title: 'Мужская раздевалка',
          locationName: 'tower',
          preview: 'man-room.png',
        },
        {
          initialPointId: 'CubeMap_76',
          containsIds: [],
          title: 'Йога',
          locationName: 'tower',
          preview: 'yoga.png',
        },
        {
          initialPointId: 'CubeMap_35',
          containsIds: [],
          title: 'Салон красоты',
          locationName: 'tower',
          preview: 'saloon.png',
        },
        {
          initialPointId: null,
          containsIds: [],
          title: 'SPA',
          locationName: 'spa',
          preview: 'spa.png',
        }
      ],
    },
    {
      name: 'spa',
      title: 'SPA',
      points: [],
      initRotation: Math.PI,
      initialPointId: 'CubeMaps_11',
      locationPoints: [
        {
          cubeId: 'CubeMaps_11',
          initialPointId: null,
          containsIds: ['CubeMaps_11', 'CubeMaps_12', 'CubeMaps_13', 'CubeMaps_10', 'CubeMaps_08'],
          title: 'Лобби',
          locationName: 'tower',
          preview: 'tower.png',
          position: new Vector3(-0.7, 0, -1.3)
        },
        {
          initialPointId: 'CubeMap_174',
          containsIds: [],
          title: 'Ресторан',
          locationName: 'tower',
          preview: 'bar.png',
        },
        {
          initialPointId: 'CubeMap_192',
          containsIds: [],
          title: 'Кафе и кинотеатр',
          locationName: 'tower',
          preview: 'coworking.png',
        },
        {
          initialPointId: 'CubeMap_80',
          containsIds: [],
          title: 'Лаунж-зона',
          locationName: 'tower',
          preview: 'lounge.png',
        },
        {
          initialPointId: 'CubeMap_47',
          containsIds: [],
          title: 'Сайкл',
          locationName: 'tower',
          preview: 'cycle.png',
        },
        {
          initialPointId: 'CubeMap_53',
          containsIds: [],
          title: 'Тренажерный зал',
          locationName: 'tower',
          preview: 'fitnes.png',
        },
        {
          initialPointId: 'CubeMap_70',
          containsIds: [],
          title: 'Женская раздевалка',
          locationName: 'tower',
          preview: 'woman-room.png',
        },
        {
          initialPointId: 'CubeMap_63',
          containsIds: [],
          title: 'Мужская раздевалка',
          locationName: 'tower',
          preview: 'man-room.png',
        },
        {
          initialPointId: 'CubeMap_76',
          containsIds: [],
          title: 'Йога',
          locationName: 'tower',
          preview: 'yoga.png',
        },
        {
          initialPointId: 'CubeMap_35',
          containsIds: [],
          title: 'Салон красоты',
          locationName: 'tower',
          preview: 'saloon.png',
        },
        {
          initialPointId: null,
          containsIds: [],
          title: 'Прачечная',
          locationName: 'prachka',
          preview: 'prachka.png',
        }
      ],
    },
    {
      name: 'tower',
      title: 'Лобби',
      points: [],
      initRotation: -Math.PI / 2,
      locationPoints: [
        {
          cubeId: 'CubeMap_189',
          initialPointId: null,
          containsIds: ['CubeMap_43', 'CubeMap_35', 'CubeMap_44', 'CubeMap_123', 'CubeMap_189'],
          title: 'SPA',
          locationName: 'spa',
          preview: 'spa.png',
          position: new Vector3(-1.4, 0, -0.5)
        },
        {
          cubeId: 'CubeMap_01',
          initialPointId: null,
          containsIds: [],
          title: 'Прачечная',
          locationName: 'prachka',
          preview: 'prachka.png',
          position: null
        }
      ],
      navigation: [
        {
          cubeId: 'CubeMap_01',
          containsIds: ['CubeMap_20', 'CubeMap_143', 'CubeMap_217', 'CubeMap_152', 'CubeMap_02', 'CubeMap_01', 'CubeMap_227'],
          title: 'Лобби',
          preview: 'lobby.png',
          rotation: -Math.PI / 2,
        },
        {
          cubeId: 'CubeMap_174',
          containsIds: ['CubeMap_165', 'CubeMap_177', 'CubeMap_179', 'CubeMap_16', 'CubeMap_174', 'CubeMap_181'],
          title: 'Ресторан',
          preview: 'bar.png',
          rotation: Math.PI / 2,
        },
        {
          cubeId: 'CubeMap_192',
          containsIds: ['CubeMap_190', 'CubeMap_192', 'CubeMap_26', 'CubeMap_195', 'CubeMap_28'],
          title: 'Кафе и кинотеатр',
          preview: 'coworking.png',
          rotation: Math.PI / 2,
        },
        {
          cubeId: 'CubeMap_80',
          containsIds: ['CubeMap_80'],
          title: 'Лаунж-зона',
          preview: 'lounge.png',
          rotation: -Math.PI / 2,
        },
        {
          cubeId: 'CubeMap_47',
          containsIds: ['CubeMap_47'],
          title: 'Сайкл',
          preview: 'cycle.png',
          rotation: 0,
        },
        {
          cubeId: 'CubeMap_53',
          containsIds: ['CubeMap_53', 'CubeMap_93', 'CubeMap_95', 'CubeMap_72', 'CubeMap_67', 'CubeMap_114', 'CubeMap_57', 'CubeMap_56', 'CubeMap_67'],
          title: 'Тренажерный зал',
          preview: 'fitnes.png',
          rotation: -Math.PI  / 5,
        },
        {
          cubeId: 'CubeMap_70',
          containsIds: ['CubeMap_70', 'CubeMap_110'],
          title: 'Женская раздевалка',
          preview: 'woman-room.png',
          rotation: 0,
        },
        {
          cubeId: 'CubeMap_63',
          containsIds: ['CubeMap_63', 'CubeMap_61'],
          title: 'Мужская раздевалка',
          preview: 'man-room.png',
          rotation: 0,
        },
        {
          cubeId: 'CubeMap_76',
          containsIds: ['CubeMap_76', 'CubeMap_77'],
          title: 'Йога',
          preview: 'yoga.png',
          rotation: 0,
        },
        {
          cubeId: 'CubeMap_35',
          containsIds: ['CubeMap_35', 'CubeMap_37', 'CubeMap_131', 'CubeMap_132'],
          title: 'Салон красоты',
          preview: 'saloon.png',
          rotation: Math.PI,
        }
      ],
    }
  ]

  static GetData(name) {
    return this.list.filter(item => item.name === name)[0]
  }

  /**
   *
   * @return {{name: string, initRotation: number, title: string, points: []}|{name: string, initRotation: number, title: string, points: []}|{initialPointId: string, name: string, initRotation: number, title: string, points: []}|{name: string, initRotation: number, title: string, points: []}|{name: string, initRotation: number, title: string, points: []}|{name: string, initRotation: number, title: string, points: []}}
   */
  static get first() {
    return this.list[0]
  }
}

export default CubeMapsData
