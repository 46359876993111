import * as BABYLON from "@babylonjs/core";
import {
  Color3,
  CubeTexture, Matrix,
  Mesh,
  PolygonMeshBuilder,
  ShaderMaterial,
  Vector2,
  Vector3
} from "@babylonjs/core";

import {isMobile} from "mobile-device-detect";

class TourCube {
  /**
   * @type {CubeData}
   * @private
   */
  _cubeData

  /**
   * @type TourSceneController
   * @private
   */
  _sceneController

  /**
   * @type {Mesh}
   * @private
   */
  _mesh

  /**
   * @type {ShaderMaterial}
   * @private
   */
  _material

  _url
  _hqUrl

  /**
   * @type {CubeTexture}
   * @private
   */
  _texture

  /**
   * @return {String}
   */
  get id () {
    return this._cubeData.id
  }

  /**
   * @return {Scene}
   * @private
   */
  get _scene () {
    return this._sceneController.scene
  }

  /**
   *
   * @return {Vector3}
   */
  get position () {
    return this._cubeData.position.clone()
  }

  get texture() {
    return this._texture
  }


  constructor({url, hqUrl, cubeData, sceneController}) {
    this._cubeData = cubeData
    this._sceneController = sceneController
    this._url = url
    this._hqUrl = hqUrl

    this._createMesh()
  }

  _createMesh() {
    const shapes = this._cubeData.getShapes()
    const meshes = []
    this._mesh = new Mesh(`cube_${this.id}`)

    shapes.forEach(shapeItem => {
      const pointsContainer = shapeItem.getShape()

      pointsContainer.forEach(shapePoints => {
        if (shapePoints.length > 0) {
          const mesh = new Mesh()
          const corners = shapePoints.map(item => new Vector2(item.x, item.y))

          const builderFront = new PolygonMeshBuilder(shapeItem.orientation, corners, this._scene)
          const builderBack = new PolygonMeshBuilder(shapeItem.orientation, corners, this._scene)
          const meshFront = builderFront.build()
          const meshBack = builderBack.build()
          meshBack.flipFaces()

          meshFront.parent = mesh
          meshBack.parent = mesh

          if (shapeItem.orientation === 'pz') {
            mesh.rotateAround(Vector3.Zero(), Vector3.Right(), -Math.PI / 2)
            mesh.position.z = this._cubeData.size / 2
          } else if (shapeItem.orientation === 'nz') {
            mesh.rotateAround(Vector3.Zero(), Vector3.Right(), -Math.PI / 2)
            mesh.position.z = -this._cubeData.size / 2
          } else if (shapeItem.orientation === 'nx') {
            mesh.rotateAround(Vector3.Zero(), Vector3.Forward(), -Math.PI / 2)
            mesh.position.x = -this._cubeData.size / 2
            mesh.rotateAround(Vector3.Zero(), Vector3.Right(), -Math.PI / 2)
          } else if (shapeItem.orientation === 'px') {
            mesh.rotateAround(Vector3.Zero(), Vector3.Forward(), -Math.PI / 2)
            mesh.position.x = this._cubeData.size / 2
            mesh.rotateAround(Vector3.Zero(), Vector3.Right(), -Math.PI / 2)
          } else if (shapeItem.orientation === 'py') {
            // mesh.rotateAround(Vector3.Zero(), Vector3.Forward(), -Math.PI / 2)
            mesh.position.y = this._cubeData.size / 2
          } else if (shapeItem.orientation === 'ny') {
            // mesh.rotateAround(Vector3.Zero(), Vector3.Forward(), -Math.PI / 2)
            mesh.position.y = -this._cubeData.size / 2
          }

          // meshFront.parent = null
          // meshBack.parent = null

          // mesh.dispose()

          meshes.push(meshFront)
          meshes.push(meshBack)
        }
      })
    })

    const resultMesh = BABYLON.Mesh.MergeMeshes(meshes, true)
    resultMesh.name = 'tour_builded_geometry'
    resultMesh.parent = this._mesh
    resultMesh.isPickable = false

    // resultMesh.material = new BABYLON.StandardMaterial('cube', this._scene)
    // resultMesh.material.diffuseColor = BABYLON.Color3.White()
    // resultMesh.material.alpha = 0.5
    // resultMesh.material.wireframe = false

    this._mesh.position = this._cubeData.position
  }

  _initMaterial() {
    const tourCubeMesh = this._mesh.getChildMeshes().filter(item => item.name === 'tour_builded_geometry')[0]

    if (tourCubeMesh && !tourCubeMesh.material) {
      const material = new ShaderMaterial(
        "shader",
        this._scene,
        {
          vertex: "transparentSkyBox",
          fragment: "transparentSkyBox",
        },
        {
          attributes: ["position", "normal", "uv"],
          uniforms: ["world", "worldView", "worldViewProjection", "view", "projection", "reflectionMatrix", "alpha"],
          needAlphaBlending: true
        },
      )

      material.setTexture('textureCubeSampler1', this._sceneController.cameraController.cube.texture)
      material.setTexture('textureCubeSampler2', this._sceneController.cameraController.cube.texture)
      material.setMatrix('reflectionMatrix1', Matrix.Translation(0, 0, 0))
      material.setMatrix('reflectionMatrix2', Matrix.Translation(0, 0, 0))
      material.setFloat('progress', 1)
      material.backFaceCulling = false
      material.emissiveColor = Color3.White()

      tourCubeMesh.material = material

      this._material = material
    }
  }

  setReflectionMatrix(matrix) {
    this._material.setMatrix('reflectionMatrix1', matrix)
  }

  setProgress(progress) {
    const val = isNaN(progress) ? 1 : progress
    if (this._material) this._material.setFloat('progress', val)
  }

  loadTexture() {
    return new Promise((resolve => {
      const url = isMobile ? this._url : this._hqUrl

      this._texture = new CubeTexture(url, this._scene, null,false, null, () => {
        resolve()
      })
    }))
  }

  unloadTexture() {
    if (this._texture && this._sceneController.cameraController.cube !== this) {
      this._texture.dispose()
      this._texture = null
    }
  }

  /**
   * @param cube {TourCube}
   */
  setActiveCube(cube) {
    this._initMaterial()

    const prevCube = this._sceneController.cameraController.prevCube

    if (this === cube) {
      // const delta = prevCube.position.subtract(this.position)
      const cameraDelta = this.position.subtract(this._sceneController.cameraController.camera.position)

      this._mesh.renderingGroupId = 1
      this._mesh.getChildMeshes().forEach(mesh => mesh.renderingGroupId = 1)
      this._material.setTexture('textureCubeSampler1', prevCube.texture)
      this._material.setMatrix('reflectionMatrix1', Matrix.Translation(cameraDelta.x,cameraDelta.y,cameraDelta.z))
      this._material.setMatrix('reflectionMatrix2', Matrix.Translation(0,0,0))
    } else {

      this._mesh.renderingGroupId = 0
      this._mesh.getChildMeshes().forEach(mesh => mesh.renderingGroupId = 0)

      const cameraDelta = this.position.subtract(this._sceneController.cameraController.camera.position)
      const cubeDelta = this.position.subtract(cube.position)

      this._material.setTexture('textureCubeSampler1', prevCube.texture)

      this._material.setMatrix('reflectionMatrix2', Matrix.Translation(cubeDelta.x,cubeDelta.y,cubeDelta.z))
      this._material.setMatrix('reflectionMatrix1', Matrix.Translation(cameraDelta.x,cameraDelta.y,cameraDelta.z))
    }
    // cube
    this._material.setTexture('textureCubeSampler2', cube.texture)
    this._material.setFloat('progress', 0)
  }
}

export default TourCube
