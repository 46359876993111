<template lang="pug">
  .navigation-bar(:class="{opened: opened}")
    .actions-buttons
      .button.open(@click="onOpenButtonClick")
      //.button.play
    .tours-list-container
      .tours-list-container-background
      .tours-list
        swiper(class="swiper", :options="swiperOptions")
          swiper-slide(v-for="(item, index) in points", :key="index")
            .tour-list-item(:key="index", @click="onItemClick(item)", :class="{selected: isSelected(item)}")
              .tour-list-item-image(:style="{backgroundImage: 'url(' + item.imageURL+')'}")
              .tour-list-item-name
                span {{item.title}}
</template>
<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {Swiper, SwiperSlide},
  name: 'NavigationBar',
  props: [
    'value',
    'activeCubeId'
  ],
  data() {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        loop: false,
        spaceBetween: 20,
        mousewheel: true,
      },
      selectedItemName: null,
      opened: false,
    }
  },

  mounted() {
    this.selectedItemName = this.value
    this.opened = true
  },

  computed: {
    cubeMapData () {
      return this.value
    },

    points() {
      const navigation = this.cubeMapData.navigation || []
      return navigation.map(item => {
        return {
          id: item.cubeId,
          title: item.title,
          imageURL: `/pics/navigation/${this.cubeMapData.name}/${item.preview}`,
          rotation: item.rotation,
          containsIds: item.containsIds,
          type: 'navigation'
        }
      }).concat(this.cubeMapData.locationPoints.map(item => {
        return {
          id: item.cubeId,
          initialPointId: item.initialPointId,
          title: item.title,
          locationName: item.locationName,
          imageURL: `/pics/navigation/${this.cubeMapData.name}/locations/${item.preview}`,
          rotation: 0,
          containsIds: [],
          type: 'location'
        }
      }))
    }
  },

  watch: {
    value(val) {
      this.selectedItemName = val
    }
  },

  methods: {
    onOpenButtonClick() {
      this.opened = !this.opened
    },

    isSelected(item) {
      return item.containsIds ? item.containsIds.indexOf(this.activeCubeId) >= 0 : false
    },

    onItemClick(item) {
      if (item.locationName) {
        if (item.type === 'location' && item.initialPointId) {
          this.$router.push(`/${item.locationName}?initialPointId=${item.initialPointId}`)
        } else {
          this.$router.push(`/${item.locationName}`)
        }

      } else {
        this.selectedItemName = item.name
        this.$emit('selected', item)
      }
    },
  }
}
</script>
